import { QuoteSearchRequest } from './models/quote-search-request';
import { Quote } from './models/quote';
import { StorageService } from './app-storage.service';
import { Storage } from '@ionic/storage-angular';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, JsonpClientBackend } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map, tap } from 'rxjs/operators';
import { User } from './models/user';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  apiUrl = environment.apiUrl;
  apiKey = 'BASIC;239394I4NDLDELPDKDMFNRUAPADFIFR';
  currentAuthTokenValue = '';
  username = '';
  savedLocally = [];

  currentUser: BehaviorSubject<User | null> = new BehaviorSubject(null);
  currentUser$: Observable<User | null> = this.currentUser?.asObservable();

  constructor(private http: HttpClient, private storageService: StorageService) {
  }

  GetCurrentUserFromStorage() {
    this.storageService.get('USER_LOGIN').then((user) => {
      this.currentUser.next(user);
    }).catch((error) => {
      console.log('Errore durante il recupero dell\'utente dallo storage:')
      console.log(error);
    });
  }

  GetCurrentToken() {
    this.storageService.get('USER_LOGIN').then((res) => {
      this.currentAuthTokenValue = res.token;
    });
  }

  UserLogin(username: string, password: string) {
    return this.http.post(this.apiUrl + '/api/mobile/authentication/v1/login', { username, password }).pipe(map((response: any) => {
      let user = response.value;
      user.username = username;
      user.password = password;
      if (response.status === 200) {
        this.storageService.set('USER_LOGIN', user).then(() => {
          console.log('user is set');
        }).catch((error) => {
          console.log('user is not set error');
          console.log(error);
        });
        this.currentUser.next(user);
      }
      return user;
    }));
  }

  UserLogout() {
    this.storageService.set('USER_LOGIN', null);
    this.currentUser.next(null);
  }

  CustomersSearch(json: string): Observable<any> {
    return this.http.post(this.apiUrl + '/api/mobile/customers/v1/search', json).pipe();
  }

  ItemsSearch(json: string): Observable<any> {
    return this.http.post(this.apiUrl + '/api/mobile/items/v1/search', json).pipe();
  }

  QuoteSave(json: string): Observable<any> {
    return this.http.post(this.apiUrl + '/api/mobile/quotes/v1/save', json).pipe();
  }

  QuoteSearch(request: QuoteSearchRequest): Observable<any> {
    return this.http.post(this.apiUrl + '/api/mobile/quotes/v1/search', JSON.stringify(request)).pipe();
  }

  QuoteSavedLocalEditModeSet(quote: Quote) {
    return this.savedLocally.push(quote);
  }

  QuoteSavedLocalEditModeGet(id) {
    let elements = this.savedLocally.filter(p => p.id == id);
    return elements?.length > 0 ? elements[0] : null;
  }

}
