export interface QuoteSearchRequest {
  filterID: number;
  filterDateStart: string;
  filterDateEnd: string;
  filterCustomerID: number;
  filterLocationName: string;
  filterCustomerFullName: string;
  filterInsertedDate: string;
}

export class QuoteSearchRequest {
  public filterID: number;
  public filterDateStart: string;
  public filterDateEnd: string;
  public filterCustomerID: number;
  public filterLocationName: string;
  public filterCustomerFullName: string;
  public filterInsertedDate: string;

  constructor() {
    this.filterID = undefined;
    this.filterDateStart = undefined;
    this.filterDateEnd = undefined;
    this.filterCustomerID = undefined;
    this.filterLocationName = undefined;
    this.filterCustomerFullName = undefined;
    this.filterInsertedDate = undefined;
  }
}
