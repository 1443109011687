import { StorageService } from './../app-storage.service';
/* eslint-disable @typescript-eslint/naming-convention */
import { AppService } from './../app.services';
// JWT interceptor
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { from, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class HttpInterceptorAuthToken implements HttpInterceptor {
    constructor(private appService: AppService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      const user = this.appService.currentUser.value;
      if (!user)
        return next.handle(request);
      if (!user.token)
        return next.handle(request);
      if (!user.userID || user.userID === 0)
        return next.handle(request);
      const token = user.token + ';' + user.userID;
      if (token) {
        const headers = {
          'Authentication-Token': token,
          'Content-Type': 'application/json',
        };
        request = request.clone({
            setHeaders: headers
        });
      }
      return next.handle(request);
    }
}
