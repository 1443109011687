import { QuoteItem } from './quoteItem';

export class Quote {
  id: number;
  customerID: number;
  customerFullName: string;
  date: any;
  timeStart: string;
  timeEnd: string;
  locationID: number;
  locationName: string;
  committentID: number;
  committentName: string;
  committentValue: string;
  committentNote: string;
  items: QuoteItem[] = [];
  notes: string;
  preamount: number;
  get itemsSubTotalAmount(): number {
    return this.items ? this.items.reduce((sum, current) => sum + current.itemPriceAmount * current.itemQuantity, 0) : 0;
  }
  get itemsTotalAmount(): number {
    return this.items ? this.items.reduce((sum, current) => sum + current.itemTotalAmount, 0) : 0;
  }
  get itemsTotalDiscountsAmount(): number {
    return this.itemsSubTotalAmount - this.itemsTotalAmount;
  }

}
