export class QuoteItem {
  itemID: number;
  itemCode: string;
  itemDescription: string;
  itemNote: string;
  itemPriceAmount: number;
  itemQuantity: number;
  itemDiscountPercent: number;
  get itemTotalAmount(): number {
    return (this.itemPriceAmount - (this.itemDiscountPercent / 100 * this.itemPriceAmount)) * this.itemQuantity;
  };
}
