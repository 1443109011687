import { AppService } from '../app.services';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class HttpInterceptorLogin implements HttpInterceptor {
    constructor(private appService: AppService, private router: Router) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(map((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
              if (event.headers.has('Authentication-Token')) {
                //this.appService.setToken = event.headers.get('Authentication-Token');
              }
            }
            return event;
          })
        );
    }
}
