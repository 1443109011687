import { Router } from '@angular/router';
/* eslint-disable no-var */
import { Component, KeyValueDiffers, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Quote } from '../../models/quote';
import { QuoteItem } from '../../models/quoteItem';
import { AlertController } from '@ionic/angular';
import { AppService } from '../../app.services';
import { DatePipe } from '@angular/common';
import { StorageService } from '../../app-storage.service';
import { AppComponent } from '../../app.component';

@Component({
  selector: 'app-quote',
  templateUrl: './quote-create.page.html',
  styleUrls: ['./quote-create.page.scss'],
})
export class QuoteCreatePage implements OnInit {
  public action: string;
  public quote: Quote = new Quote();
  public customers: any;
  public items: any;
  public locations: any[] = [];
  public quoteCurrentLocation: any;
  searching = false;
  isLoading = false;
  userFeedback = '';
  saved: boolean = false;
  savedQuote: Quote;
  title: string = 'Nuovo preventivo';
  invalidDate: string;
  export: boolean = false;

  constructor(private activatedRoute: ActivatedRoute, public alertController: AlertController, private service: AppService,
    private storageService: StorageService, private appComponent: AppComponent, private router: Router) { }

  ngOnInit() {
    this.createNewQuote();
  }

  ionViewWillEnter() {
  }

  ngDoCheck() {
    this.saved = JSON.stringify(this.savedQuote) == JSON.stringify(this.quote);
  }

  createNewQuote() {
    this.quote = new Quote();
    let today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0');
    const yyyy = today.getFullYear();
    this.quote.date = dd + '/' + mm + '/' + yyyy;
    this.quote.timeStart = '00:00';
    this.quote.timeEnd = '23:59';
    this.quote.preamount = 0.00;
    this.saved = false;
  }

  quoteItemQuantityIncr(item: QuoteItem) {
    item.itemQuantity++;
  }

  quoteItemQuantityDecr(item: QuoteItem) {
    if (item.itemQuantity > 1) {
      item.itemQuantity--;
    }
  }

  async presentDecimalAlertPrompt(propertyValue: number, propertyName: string, item: QuoteItem) {
    const alert = await this.alertController.create({
      header: 'Modifica ' + propertyName,
      inputs: [
        {
          name: 'quantity',
          type: 'number',
          min: 0,
          value: propertyValue,
          placeholder: 'Inserisci prezzo unitario',
          attributes: {
            maxlength: 4,
            inputmode: 'decimal'
          }
        }
      ],
      buttons: [
        {
          text: 'Annulla',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            console.log('Annullata modifica');
          }
        }, {
          text: 'Salva',
          handler: (data) => {
            this.quoteItemSetPropertyByAlert(propertyName, data.quantity, item);
          }
        }
      ]
    });

    await alert.present();
  }

  quoteItemSetPropertyByAlert(propertyName: string, value: string, item: QuoteItem) {
    // Check value is valid
    if (value !== '') {
      const fValue = parseFloat(value);
      switch (propertyName) {
        case 'prezzo unitario':
          item.itemPriceAmount = fValue;
          break;
        case 'sconto %':
          item.itemDiscountPercent = fValue <= 100 ? fValue : item.itemDiscountPercent;
          break;
      }
    }
  }

  quoteItemRemove(item: QuoteItem) {
    this.quote.items.splice(this.quote.items.indexOf(item), 1);
  }

  preflightValue(value) {
    return value && value.length >= 2;
  }

  searchCustomer(value) {
    if (this.preflightValue(value) && !this.searching) {
      this.searching = true;
      var req: any = {};
      req.value = value;
      this.service.CustomersSearch(JSON.stringify(req)).subscribe((res) => {
        this.searching = false;
        var customers = res?.value ?? [];
        if (customers.length === 1) {
          this.setCustomer(customers[0]);
        } else {
          this.customers = customers;
        }
      });
    } else {
      this.customers = [];
    }
    this.quote.customerID = 0;
    this.quote.customerFullName = '';
  }

  setCustomer(customer) {
    this.quote.customerID = customer?.id;
    this.quote.customerFullName = customer?.fullname;
    this.locations = customer.locations;
    this.quoteCurrentLocation = this.locations?.length > 0 ? this.locations[0] : null;
    this.customers = [];
  }

  searchItem(value) {
    if (this.preflightValue(value) && !this.searching) {
      this.searching = true;
      var req: any = {};
      req.value = value;
      this.service.ItemsSearch(JSON.stringify(req)).toPromise().then((res) => {
        this.items = res?.value ?? [];
      }).catch((err) => {
        this.items = [];
      }).finally(() => {
        this.searching = false;
      });
    } else {
      this.items = [];
    }
  }

  setItem(item) {
    var found = this.quote.items.filter(p => p.itemID === item.id);
    if (found.length === 0) {
      var obj = new QuoteItem();
      obj.itemID = item.id;
      obj.itemCode = item.code;
      obj.itemDescription = item.description;
      obj.itemQuantity = 1.00;
      obj.itemPriceAmount = item.price ?? 0.00;
      obj.itemDiscountPercent = 0.00;
      this.quote.items.push(obj);
      console.log('Added!');
    } else {
      found[0].itemQuantity++;
      console.log('Found!');
    }
  }

  preflightSave() {
    this.userFeedback = 'Controllo dati in corso...';

    if (!this.checkDate(this.quote.date)) {
      this.userFeedback = 'Formato data non valido, inserire GG/MM/ANNO';
      return false;
    }

    if (!this.quote.locationName || this.quote.locationName === '' || this.quote.locationID === 0) {
      this.userFeedback = 'Sala non valida, inserire una sala';
      return false;
    }

    return true;
  }

  saveQuote() {
    this.quote.locationID = this.quoteCurrentLocation?.id;
    this.quote.locationName = this.quoteCurrentLocation?.description;
    this.saveLocal();
    if (this.preflightSave() && !this.isLoading) {
      this.userFeedback = 'Salvataggio in corso, attendere prego...';
      this.isLoading = true;
      var req: any = {};
      req.quote = this.quote;
      req.quote.username = this.service.currentUser.value.username;
      this.service.QuoteSave(JSON.stringify(req)).toPromise().then((res) => {
        this.quote.id = res.value.id;
        this.userFeedback = 'OK - Preventivo salvato con successo, numero: ' + res.value.id;
        this.savedQuote = new Quote();
        Object.assign(this.savedQuote, this.quote);
      }).catch((err) => {
        this.userFeedback = 'Preventivo non salvato, riprovare!';
        console.log(err);
      }).finally(() => {
        this.isLoading = false;
        this.saveLocal();
      });
    }
  }
  saveLocal() {
    const myQuote: any = this.quote;
    myQuote.username = this.service.currentUser.value.username;

    const idx = this.appComponent.userQuotes.findIndex(p => p.id === myQuote.id);

    if (idx > -1) {
      this.appComponent.userQuotes.splice(idx, 1);
    }

    this.appComponent.userQuotes.push(myQuote);

    this.storageService.set('USER_QUOTES', this.appComponent.userQuotes);
  }

  checkDate(date: string): boolean {
    if (date.length !== 10)
      return false;
    let split = date.split('/');
    if (split.length !== 3)
      return false;
    return true;
  }


}
