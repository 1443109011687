import { AppService } from './../app.services';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { Injectable } from '@angular/core';
import { User } from '../models/user';

@Injectable({
    providedIn: 'root'
})

export class UserGuard implements CanActivate {
    
    constructor(private service: AppService, private router: Router) {

    }
    
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        const user: User = this.service.currentUser?.value;
        const canActivate: boolean = (user?.loginID > 0 || user?.userID > 0) && user?.token?.length > 0;
        if (!canActivate) {
            this.router.navigateByUrl('login');
        }
        return canActivate;
    }

}
