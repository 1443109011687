import { AlertController } from '@ionic/angular';
import { StorageService } from './../app-storage.service';
import { AppService } from '../app.services';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Alert } from 'selenium-webdriver';

@Injectable()
export class HttpInterceptorLogout implements HttpInterceptor {
    constructor(private appService: AppService, private router: Router, private storageService: StorageService,
      private alertController: AlertController) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      return next.handle(request).pipe(map((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            if (event.status === 401 || event.body && event.body.status === 401) {
              this.appService.UserLogout();

              this.alertController.create({
                header: 'Sessione scaduta',
                message: 'Per motivi di sicurezza è necessario eseguire di nuovo l\'accesso.',
                buttons: ['Ok']
              }).then((alert) => {
                alert.present();
                alert.onDidDismiss().then(() => {
                  this.router.navigateByUrl('login');
                });
              });
            }
          }
          return event;
        })
      );
  }
}
