import { User } from './../models/user';
/* eslint-disable max-len */
import { StorageService } from './../app-storage.service';
import { AppComponent } from './../app.component';
import { AppService } from './../app.services';
import { Component, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.css']
})
export class LoginPage implements OnInit {

  loginForm: FormGroup= this.formBuilder.group({
    username: new FormControl('', Validators.required),
    password: new FormControl('', Validators.required),
  });
  user;
  feedback: string = '';
  error: string = '';
  isLoading: boolean = false;
  isError: boolean = false;
  redirectAfterLogin: string = '/quote/create';

  constructor(private activatedRoute: ActivatedRoute, private appService: AppService, private router: Router,
    private appComponent: AppComponent, private storageService: StorageService, private formBuilder: FormBuilder) {
  }

  ngOnInit() {
    this.appService.currentUser$.subscribe((user) => {
      this.user = user;
      if (this.user)
        this.router.navigateByUrl(this.redirectAfterLogin);
    });
  }

  ionViewWillEnter() {
    if (this.user)
      this.router.navigateByUrl(this.redirectAfterLogin);
  }

  login() {
    if (!this.loginForm.valid)
      return;

    if (this.isLoading)
      return;
    
    this.feedback = 'Verifico autenticazione...';
    this.isLoading = true;
    this.isError = false;

    this.appService.UserLogin(this.loginForm.value.username, this.loginForm.value.password).subscribe((user) => {
      if (!user || user.userID === 0) {
        this.feedback = 'Credenziali non riconosciute';
      }
    }, (error) => {
      console.log(error);
      this.feedback = 'Impossibile collegarsi al server';
      this.isLoading = false;
      this.isError = true;
      this.error = error.message;
    }, () => {
      this.isLoading = false;
    });
  }

}
