import { AlertController } from '@ionic/angular';
import { AppService } from './app.services';
import { Quote } from './models/quote';
import { StorageService } from './app-storage.service';
import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public pages = [
    { title: 'Nuovo', url: '/quote/create', icon: 'add' },
    { title: 'Ricerca', url: '/quote/search', icon: 'search' }
  ];
  public actions = [{ label: 'Esporta', users: ['all']}, { label: 'Invia tramite email', users: ['operator']}];
  public appVersion = environment.version;
  userQuotes: Quote[] = [];


  user;


  constructor(private storageService: StorageService, private router: Router, private appService: AppService, private alertController: AlertController) {}

  async ngOnInit() {
    await this.storageService.init().then(() => {
      this.appService.GetCurrentUserFromStorage();
    }).catch((error) => {
      this.alertController.create({ header: 'Attenzione', message: 'Applicazione non disponibile su questo dispositivo.'}).then((alert) => alert.present());
    });

    this.appService.currentUser$.subscribe((user) => {
      this.user = user;
    });
  }

  logout() {
    this.appService.UserLogout();
  }

}
