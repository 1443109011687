import { QuoteSearchRequest } from '../../models/quote-search-request';
/* eslint-disable no-var */
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Quote } from '../../models/quote';
import { AlertController, IonProgressBar } from '@ionic/angular';
import { AppService } from '../../app.services';


@Component({
  selector: 'app-quote',
  templateUrl: './quote-search.page.html',
  styleUrls: ['./quote-search.page.scss'],
})
export class QuoteSearchPage implements OnInit {

  searchRequest: QuoteSearchRequest = new QuoteSearchRequest();
  customers: any;
  items: any;
  quotes: Quote[] = [];
  searching = false;
  resultFeedback: String = 'Compila almeno un campo per avviare la ricerca.';
  

  

  constructor(public router: Router, private activatedRoute: ActivatedRoute, private appService: AppService, private alertController: AlertController) { }

  ngOnInit() {
    

  }

  ionViewWillEnter() {
    //this.action = this.activatedRoute.snapshot.paramMap.get('id');

    let date = (new Date(new Date().getTime() - (31 * 24 * 60 * 60 * 1000)));

    let dd = String(date.getDate()).padStart(2, '0');
    let mm = String(date.getMonth() + 1).padStart(2, '0');
    let yyyy = date.getFullYear();

    this.searchRequest.filterDateStart = dd + '/' + mm + '/' + yyyy;

    date = new Date();
    
    dd = String(date.getDate()).padStart(2, '0');
    mm = String(date.getMonth() + 1).padStart(2, '0');
    yyyy = date.getFullYear();

    this.searchRequest.filterDateEnd = dd + '/' + mm + '/' + yyyy;
  }

 

  searchFilter() {
   
    console.log(this.searchRequest);

    let uguali: Boolean = JSON.stringify(this.searchRequest) == JSON.stringify(new QuoteSearchRequest());

    if (uguali == true) {
     
      this.alertController.create({
        header: 'Attenzione',
        message: 'Ricerca non riuscita, compila almeno un campo sopra indicato.',
        buttons: ['Ok']
      }).then((alert) => alert.present());
    } else {

    console.log(this.searchRequest);
      this.resultFeedback = 'Nessun risultato trovato.';
      this.searching = true;

      let req: any = {};
      
      Object.assign(req, this.searchRequest);

      let startDate = req.filterDateStart;
      startDate = startDate.split('/');
      req.filterDateStart = startDate[1] + '/' + startDate[0] + '/' + startDate[2];

      startDate = req.filterDateEnd;
      startDate = startDate.split('/');
      req.filterDateEnd = startDate[1] + '/' + startDate[0] + '/' + startDate[2];

      this.appService.QuoteSearch(req).subscribe((quotes) => {
        this.quotes = quotes?.value ?? [];
        this.searching = false;
      }, (error) => {
        this.searching = false;
        this.alertController.create({
          header: 'Attenzione',
          message: 'Ricerca non riuscita, controlla la connessione ad internet e riprova.',
          buttons: ['Ok']
        }).then((alert) => alert.present());
      });
    }
      
  }

  transformDate(date): string {
    const dateCheck = new Date(date);
    let month = String(dateCheck.getMonth() + 1);
    let day = String(dateCheck.getDate());
    const year = String(dateCheck.getFullYear());
    if (month.length < 2) { month = '0' + month; }
    if (day.length < 2) { day = '0' + day; };
    return day + '/' + month + '/' + year;
  }

  edit(quote){
    this.appService.QuoteSavedLocalEditModeSet(quote);
    this.router.navigate(['/quote/edit/' + quote.id]);
  }

}

